<!--  -->
<template>
  <div>
    <blockTitle title="集群节点"></blockTitle>
    <el-card shadow="never">
      <el-table :data="tableData" stripe size="small" class="w-full">
        <el-table-column label="#" type="expand">
          <template slot-scope="{ row }">
            <p
                class="text-gray-800 text-sm mb-2 border-l-4 border-blue-400 pl-3"
            >
              集群内主机
            </p>
            <computedItem
                v-for="(item, index) in row.list"
                :item="item"
                :key="index"
            ></computedItem>
          </template>
        </el-table-column>
        <el-table-column prop="nodeName" label="集群名称"></el-table-column>
        <el-table-column label="主机数量" width="180">
          <template slot-scope="{ row }">
            {{ row.list.length }}
          </template>
        </el-table-column>
        <el-table-column label="运维人员信息">
          <template slot-scope="{ row }">
            <p class="text-xs text-gray-400">
              {{ row.opsRealName || '运维人员名称' }}
            </p>
            <p class="text-xs text-gray-400">
              {{ row.opsPhone || '运维人员手机' }}
            </p>
          </template>
        </el-table-column>
        <el-table-column label="状态">
          <template slot-scope="{ row }">
            <div
                class="w-16 h-6 bg-blue-50 border border-blue-200 text-xs text-center flex justify-center align-items-center text-blue-400"
                v-if="row.state === 1"
            >
              启用
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="address" label="客户数量">
          <template slot-scope="{ row }">
            <p class="text-gray-600 text-xs mb-1">
              共 {{ row.customerUseCount }} 个客户使用
            </p>
            <p class="text-gray-600 text-xs">
              已分配 {{ row.mountDeviceCount }} 台设备
            </p>
          </template>
        </el-table-column>
      </el-table>
    </el-card>
  </div>
</template>
<style scoped lang="less" rel="stylesheet/less"></style>
<script type="text/ecmascript-6">
import computedItem from '@/components/common/computedItem'

export default {
  data () {
    return {
      tableData: []
    }
  },
  components: {
    computedItem
  },
  methods: {
    getData () {
      this.$api.sysNode.page().then(res => {
        this.tableData = res.data.content
      })
    }
  },
  mounted () {
    this.getData()
  }
}
</script>
