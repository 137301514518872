<!-- 展示容器资源 -->
<template>
  <div class="server-item">
    <div class="title truncate text-sm font-bold">
      <div
          class="indicator mt-1.5"
          style="background-color: rgb(15, 190, 143); margin-right: 5px"
      >
        {{ item.hostType === 1 ? '主' : '工' }}
      </div>
      {{ item.hostName }}
    </div>
    <el-tooltip class="item" effect="dark" placement="top">
      <div slot="content">
        <p v-if="item.ipInfo.length === 0">无外网IP</p>
        <p v-if="item.ipInfo.length === 1">
          {{ item.ipInfo[0].ip }} - {{ item.ipInfo[0].net }}M
        </p>
        <template v-else>
          <p v-for="(ipItem, ipIndex) in item.ipInfo" :key="ipIndex">
            {{ ipItem.ip }} - {{ ipItem.net }}M
          </p>
        </template>
      </div>
      <div class="nodeIp pb-1">
        <p v-if="item.ipInfo.length === 0">无外网IP</p>
        <p v-else-if="item.ipInfo.length === 1">
          {{ item.ipInfo[0].ip }} - {{ item.ipInfo[0].net }}M
        </p>
        <p v-else>含有多条IP线路</p>
      </div>
    </el-tooltip>
    <div style="" class="flex flex-col mt-1 text text-xs text-gray-500">
      <div>CPU {{ item.cpu }}核</div>
      <div>内存 {{ item.ram }}MB</div>
      <div>硬盘 {{ item.diskSize }}G</div>
      <div class="truncate" style="">系统版本 {{ item.sysVersion }}</div>
    </div>
  </div>
</template>
<style scoped lang="less" rel="stylesheet/less">
.server-item {
  border: 1px dashed #a7b6cd;
  border-radius: 8px;
  display: inline-block;
  padding: 7px;
  user-select: none;
  cursor: pointer;
  //height: 92px;
  width: 230px;
  margin: 5px;
  overflow: hidden;
  transition: 0.2s;
  background-color: #e9edf3;
  background-position: 150px 10px;
  background-size: 40px 40px;

  .title {
    .indicator {
      border-radius: 60px;
      text-align: center;
      font-size: 13px;
      height: 20px;
      width: 20px;
      line-height: 20px;
      font-weight: 800;
      color: #fff;
      display: inline-block;
      vertical-align: top;
    }
  }

  .nodeIp {
    line-height: 14px;
    font-size: 12px;
    padding-left: 28px;
    color: #666;
    border-bottom: 1px dashed #a7b6cd;
  }
}
</style>
<script type="text/ecmascript-6">
export default {
  data () {
    return {}
  },
  props: {
    item: {}
  },
  methods: {},
  mounted () {
  }
}
</script>
